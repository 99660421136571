body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #282c34;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'BebasNeue';
  src: local('BebasNeue'), url(./assets/Bebas_Neue/BebasNeue-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato';
  src: local('Lato'), url(./assets/Lato/Lato-Regular.ttf) format('truetype');
}
